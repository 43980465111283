import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from '../layouts/dashboard';
import SimpleLayout from '../layouts/simple';
import { isLoggedIn } from '../helpers/auth.helper';
//
// import BlogPage from '../pages/blogSection/BlogPage';
import EnquiryPage from '../pages/EnquiresPage';
import LoginPage from '../pages/LoginPage';
import Page404 from '../pages/Page404';
import ProductsPage from '../pages/ProductsPage';
import DashboardAppPage from '../pages/DashboardAppPage';
import BlogSection from '../pages/blogSection';
import ArtGallerySection from '../pages/artGallery';

// ----------------------------------------------------------------------

export default function Router() {

  // const isUserLoggedIn = isLoggedIn();
  const isClientLoggedIn = isLoggedIn();

  const routes = [
    
    {
      path: 'login',
      element: <LoginPage />,
    },
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/login" />, index: true },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to={isClientLoggedIn ? "/404" : '/login'} /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ];

  const privateRoutes = isClientLoggedIn ? [
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: 'app', element: <DashboardAppPage /> },
        { path: 'enquires', element: <EnquiryPage /> },
        { path: 'products', element: <ProductsPage /> },
        { path: 'blog',
          children: [
            { element: <BlogSection />, index: true },
            { path: ':page', element: <BlogSection />, index: true }, // true for news/add news/view news/edit
            { path: ':page/:id', element: <BlogSection />, index: true }, // true for news/add news/view news/edit
          ],
        },
        { path: 'art-gallery',
          children: [
            { element: <ArtGallerySection />, index: true },
            { path: ':page', element: <ArtGallerySection />, index: true },
            { path: ':page/:id', element: <ArtGallerySection />, index: true },
          ],
        },
      ],
    },
  ] : [];

  return useRoutes(routes.concat(privateRoutes));
}
