import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { useEffect, useState } from 'react';
import moment from 'moment/moment';
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
} from '@mui/material';
// components
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';
// mock
import { deleteEnquiry, getEnquires } from '../services/api/enquires';
import config from '../config/config';
import { showSnackbar } from '../components/Notification/Snackbar';
import ModalPopUp from '../components/modal/modal.popup';
// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'email', label: 'Email', alignRight: false },
  { id: 'phone', label: 'Phone', alignRight: false },
  { id: 'createdAt', label: 'Enquired on', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: '' },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function EnquiryPage() {
  const [open, setOpen] = useState(null);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('desc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('createdAt');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [enquires, setEnquires] = useState([]);
  const [selectedId, setSelectedId] = useState(null);

  /**
  the legendary use effect function
  This will run very first on render
   */
  useEffect(() => {
    getEnquiresAs()
    return () => {
      setEnquires([])
    };
  }, []);

  // get users from api
  const getEnquiresAs = () => {
    getEnquires().then((data) => setEnquires(data)).catch(() => showSnackbar().failure('Something went wrong'))

  }

  /**
   * Delete the selected enquiry by id
   */
  const deleteEnquiryAs = () => {
    if (selectedId) {
      deleteEnquiry(selectedId).then((data) => {
        if (data.success) {
          showSnackbar().success(data.message);
          getEnquiresAs();
        } else {
          showSnackbar().failure(data.message);
        }
      })
      setOpenDeletePopup(false);
    }
  }



  const handleOpenMenu = (event, id) => {
    setOpen(event.currentTarget);
    setSelectedId(id)
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };


  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = enquires.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - enquires.length) : 0;

  const filteredUsers = applySortFilter(enquires, getComparator(order, orderBy), filterName);

  const isNotFound = !filteredUsers.length && !!filterName;
  const { appName } = config


  // View selected enquires as popup
  const [openViewEnquiresDialog, setOpenViewEnquiresDialog] = React.useState(false);
  const [selectedUserView, setSelectedUserView] = React.useState({});
  const viewEnquiryAs = () => {
    if (selectedId) {
      const selectedView = enquires.find(selectedUser => selectedUser._id === selectedId);
      setSelectedUserView(selectedView);
      setOpen(null);
    }
  }
  const [openDeletePopup, setOpenDeletePopup] = React.useState(false);
  return (
    <>
      <Helmet>
        <title> Enquires | {appName} </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Enquires
          </Typography>

        </Stack>

        <Card>
          <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={enquires.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                    const { _id: id, name, email, phone, createdAt } = row;
                    const selectedUser = selected.indexOf(name) !== -1;

                    return (
                      <TableRow hover key={id} tabIndex={-1} role="checkbox" selected={selectedUser}>
                        <TableCell padding="checkbox">
                          <Checkbox checked={selectedUser} onChange={(event) => handleClick(event, name)} />
                        </TableCell>

                        {/* <TableCell component="th" scope="row" padding="none">
                          <Stack direction="row" alignItems="center" spacing={2}>
                            <Avatar alt={name} src={avatarUrl} />
                            <Typography variant="subtitle2" noWrap>
                              {name}
                            </Typography>
                          </Stack>
                        </TableCell> */}

                        <TableCell align="left">{name}</TableCell>

                        <TableCell align="left">{email}</TableCell>
                        <TableCell align="left">{phone}</TableCell>
                        <TableCell align="left">{moment(createdAt).format('MM-DD-YYYY h:m a')}</TableCell>

                        {/* <TableCell align="left">{isVerified ? 'Yes' : 'No'}</TableCell> */}

                        {/* <TableCell align="left">
                          <Label color={(status === 'banned' && 'error') || 'success'}>{sentenceCase(status)}</Label>
                        </TableCell> */}

                        <TableCell align="right">
                          <IconButton size="large" color="inherit" onClick={(e) => handleOpenMenu(e, id)}>
                            <Iconify icon={'eva:more-vertical-fill'} />
                          </IconButton>

                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Not found
                          </Typography>

                          <Typography variant="body2">
                            No results found for &nbsp;
                            <strong>&quot;{filterName}&quot;</strong>.
                            <br /> Try checking for typos or using complete words.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={enquires.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem onClick={() => {
          setOpenViewEnquiresDialog(true);
          viewEnquiryAs(selectedId)
        }}>
          <Iconify icon={'eva:eye-fill'} sx={{ mr: 2 }} />
          View
        </MenuItem>

        <MenuItem
          // onClick={deleteEnquiryAs}
          onClick={() => {
            setOpenDeletePopup(true);
            viewEnquiryAs(selectedId)
          }}
          sx={{ color: 'error.main' }}>
          <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
          Delete
        </MenuItem>
      </Popover>

      {openViewEnquiresDialog && <ModalPopUp
        isOpen={openViewEnquiresDialog}
        onClose={() => {
          setOpenViewEnquiresDialog(false);
        }}
        className={'modal-popup enquiry-popup'}
        children={<div className='view-details'>
          <div className='each-details-blk'>
            <p><b>Name : </b> {selectedUserView.name ? selectedUserView.name : '-' }</p>
          </div>
          <div className='each-details-blk'>
            <p><b>Email : </b> {selectedUserView.email ? selectedUserView.email : '-'}</p>
          </div>
          <div className='each-details-blk'>
            <p><b>Phone : </b> {selectedUserView.phone ? selectedUserView.phone : '-'}</p>
          </div>
          <div className='each-details-blk'>
            <p><b>Created Date : </b> {selectedUserView.createdAt ? moment(selectedUserView.createdAt).format('MM-DD-YYYY h:m a') : '-'}</p>
          </div>
          <div className='each-details-blk'>
            <p><b>Why do you need to develop technology? : </b> {selectedUserView.tech_comment ? selectedUserView.tech_comment : '-'}</p>
          </div>
          <div className='each-details-blk'>
            <p><b>What are the top 5 features? : </b> {selectedUserView.top_feature ? selectedUserView.top_feature : '-'}</p>
          </div>
          <div className='each-details-blk'>
            <p><b>Other secondary features? : </b> {selectedUserView.other_feature ? selectedUserView.other_feature : '-'}</p>
          </div>
          <div className='each-details-blk'>
            <p><b>What’s your expertise related to the app/ technology you want to develop? : </b> {selectedUserView.expertise_app ? selectedUserView.expertise_app : '-'}</p>
          </div>
          <div className='each-details-blk'>
            <p><b>What is the business model? : </b> {selectedUserView.business_model ? selectedUserView.business_model : '-'}</p>
          </div>
          <div className='each-details-blk'>
            <p><b>In which geographies will it operate at first? : </b> {selectedUserView.geographies ? selectedUserView.geographies : '-'}</p>
          </div>
        </div>}

      />}

      {openDeletePopup && <ModalPopUp
        isOpen={openDeletePopup}
        onClose={() => {
          setOpenDeletePopup(false);
        }}
        className={'modal-popup delete-popup'}
        children={<div className='delete-enquiry-box'>
          <p>Are you sure you want to delete this enquiry? All the information will be lost. This action cannot be undone. </p>
          <div className='btn-wrp'>
            <button className='cancel-btn' type='button' onClick={() => {setOpenDeletePopup(false); }}>Cancel</button>
            <button className='confirm-btn' type='button' onClick={() => {deleteEnquiryAs()}}>Delete</button>
          </div>
        </div>}

      />}
    </>
  );
}
