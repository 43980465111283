import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
// @mui
import { Button, Card, Container, IconButton, Stack, Typography } from '@mui/material';
// Components

import AppTable from '../../components/table/index';
import ApiCaller from '../../services/api/general';
import { showSnackbar } from '../../components/Notification/Snackbar';
import { DATE_READABLE_FORMAT } from '../../constants/index';
import SVGIcons from '../../assets/images/icons/svgIcons';
import Iconify from '../../components/iconify';
import config from '../../config/config';




const BlogPage = () => {
    const navigate = useNavigate();
    const [isRefreshData, setIsRefreshData] = useState(false);

    /**
  * status update API
  * @param {string} id 
  * @param {string} status 
  */
    const toggleActiveStatus = (id, status) => {
        status = status === "DRAFT" ? "PUBLISHED" : "DRAFT";
        const statusMsg = status === "PUBLISHED" ? "published" : "drafted";
        if (id) {
            const apiCaller = new ApiCaller('blog');
            apiCaller.customPatchData(id, { status })
                .then(() => showSnackbar().success(`The selected news has been ${statusMsg} successfully.`))
                .catch(() => showSnackbar().failure("Something went wrong!."))
                .finally(() => {
                    setIsRefreshData(true);
                })
        }

    }



    /**
     * Columns array to show 
     */
    const tableColumns = [
        {
            field: 'title',
            headerName: 'Title',
            minWidth: 180,
            flex: 1.5,

        },
        {
            field: 'author',
            headerName: 'Author',
            flex: 1,
            minWidth: 180,
            align: 'center',
            headerAlign: 'center',
        },
        {
            field: 'createdAt',
            headerName: 'Created date',
            flex: 1,
            minWidth: 120,
            valueFormatter: ({ value }) => dayjs(value).format(DATE_READABLE_FORMAT),
            align: 'center',
            headerAlign: 'center',
        },
        {
            field: 'status',
            headerName: 'Status',
            flex: 0.5,
            minWidth: 90,
            maxWidth: 90,
            sortable: false,
            align: 'center',
            headerAlign: 'center',
            renderCell: (params) => {
                console.log(params)
                const { status, id } = params.row;
                return <span style={{ display: 'flex', gap: '2px' }}><IconButton onClick={() => toggleActiveStatus(id, status)} children={status === 'PUBLISHED' ? <SVGIcons.StatusActiveIcon /> : <SVGIcons.StatusInactiveIcon />} /></span>
            }
        }

    ];

    /**
     * Used to build the data source url to fetch data from
     * @returns string from where the table data is loaded
     */
    const getDataURL = () => `blog`;

    /**
     * 
     * @param {Object} row 
     * @returns Array of extra columns needed
     */
    const getExtraActionColumns = (row) => [
        {
            label: row?.status === "PUBLISHED" ? "Mark as draft" : "Publish",
            onClick: () => {
                toggleActiveStatus(row?.id, row?.status)
            }
        }
    ]

    const { appName } = config

    return (

        <>

            <Helmet>
                <title> Blog | {appName} </title>
            </Helmet>

            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Blog
                    </Typography>
                    <Button onClick={() => navigate('add')} variant="contained" startIcon={<Iconify icon="eva:plus-fill" />}>
                        New Post
                    </Button>
                </Stack>

                <Card>

                    <div className='news-main-list f-wrp'>
                        <div className='panel-tabel-sec f-wrp'>

                            <AppTable
                                columns={tableColumns}
                                dataURL={getDataURL()}
                                isRefreshData={isRefreshData}
                                onRefreshSuccess={() => setIsRefreshData(false)}
                                extraActions={getExtraActionColumns}
                                actionColumnSettings={{ maxWidth: 170, minWidth: 170 }}
                                checkboxSelection
                            // mobileRowRender={renderMobileRow}
                            />

                        </div>
                    </div>
                </Card>
            </Container>
        </>
    )
}


BlogPage.propTypes = {}
export default BlogPage;