import axios from "axios";
import config from "../../config/config";
import { getAuthToken, isLoggedIn, logoutUser } from "../../helpers/auth.helper";
import { showSnackbar } from '../../components/Notification/Snackbar';

// REACT_APP_API_URL
const { apiURL } = config

const axiosInstance = axios.create({
  baseURL: apiURL,
  // timeout: 1000,
  // headers: { 'Authorization': `Bearer ${getAuthToken()}` }
});

axiosInstance
  .interceptors
  .request
  .use((config)=> {

    if (getAuthToken()) {
      config.headers.Authorization = `Bearer ${getAuthToken()}`;
    }
    return config;
  }, (error)=> Promise.reject(error));

axiosInstance.interceptors.response.use((response) => response,
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  (error) => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    if (isLoggedIn() && error.response.status === 401) {
      showSnackbar({}, () => logoutUser()).warning('Session expired. Please login');
    }
    return Promise.reject(error);
  });

export default axiosInstance;