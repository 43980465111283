import * as React from 'react';
import { useParams } from 'react-router-dom';

import './index.scss';

import AddEditArtGallery from './addEditArtGallery';
import ArtGalleryList from './list';


export default function ArtGallerySection() {
    const { page = 'list' } = useParams()

    const getSelectedContent = () => {
        const selectedItem = page;
        let contentSelected = <ArtGalleryList />
        switch (selectedItem) {
            case 'list':
                contentSelected = <ArtGalleryList />;
                break;
            case 'view':
                contentSelected = <AddEditArtGallery />;
                break;
            case 'add':
                contentSelected = <AddEditArtGallery />;
                break;
            case 'edit':
                contentSelected = <AddEditArtGallery />;
                break;
            default:
                contentSelected = <span>Not found</span>;
                break;
        }
        return contentSelected;
    }

    return (
        <div>
            <div className="main-content-wrapper art-main-wrapper f-wrp">
                {getSelectedContent()}
            </div>
        </div >
    );
}