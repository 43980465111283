import { EditorState, convertFromRaw, convertToRaw } from 'draft-js';
import React from 'react';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { PropTypes } from 'prop-types';
import './richText.scss';


const  RichTextEditor = (props) => {
const {onChange, defaultValue = ''} = props;
const defaultVal = defaultValue ? EditorState.createWithContent(convertFromRaw(JSON.parse(defaultValue))) : EditorState.createEmpty();
    const [editorState, setEditorState] = React.useState(defaultVal);

    const editor = React.useRef(null);

    /**
     * On change the text
     */
    const onStateChange = () => {
        const value = convertToRaw(editorState.getCurrentContent())
        if (typeof onChange === 'function') {
            onChange(value);
        }
    }

    return (
        <div className='rich-text-editor-wrp'>
            <Editor
            ref={editor}
            toolbarOnFocus
            toolbar={{
                options: ['inline', 'fontSize', 'list', 'textAlign', 'history'],
                }}
                editorState={editorState}
                toolbarClassName="toolbarClassName"
                wrapperClassName="wrapperClassName"
                editorClassName="editorClassName"
                onEditorStateChange={setEditorState}
                onContentStateChange={onStateChange}
            />
        </div>
    );
}
RichTextEditor.propTypes = {
    onChange: PropTypes.func,
    defaultValue: PropTypes.string
  };
export default RichTextEditor;