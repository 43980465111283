import en from '../translations/en.json';
import es from '../translations/es.json';


/**
 * translate the key based on user language selection
 * @param {string} key string
 * @returns string
 */

export const translateThis = (key = '') => {
    try {
        // const appState = JSON.parse(window.sessionStorage.getItem('xmed-atom-persist'));
        // const languageState = appState?.appConfigAtomState || {};

        // const userLanguage = languageState.selectedLang || 'en';
        const userLanguage = 'en';
        let languageFile = {};

        switch (userLanguage) {
            case 'en':
                languageFile = en;
                break;
            case 'es':
                languageFile = es;
                break;
            default:
                languageFile = en;
                break;
        }
        return languageFile[key] || key;
    } catch (e) {
        return key;

    }
}

