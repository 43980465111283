import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import { Stack, IconButton, InputAdornment, TextField, Divider } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Iconify from '../../../components/iconify';
import { login } from '../../../services/api/auth';
import { showSnackbar } from '../../../components/Notification/Snackbar';

// ----------------------------------------------------------------------

export default function LoginForm() {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [password, setPassword] = useState('');

  const onSubmit = () => {
    setIsLoading(true);
    login({ email, password }).then((data) => {
      if (data.success) {
        navigate('/dashboard', { replace: true });
        showSnackbar().success(data.message)
      } else {
        showSnackbar().failure(data.message)

      }
    }).finally(() => setIsLoading(false))
      ;
  };

  return (
    <>
      <Stack spacing={3}>
        <TextField type='email' onChange={(e) => setEmail(e.target.value)} name="email" label="Email address" />

        <TextField
          name="password"
          label="Password"
          onChange={(e) => setPassword(e.target.value)}
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
        <Divider />
      </Stack>

      <LoadingButton
        loading={isLoading}
        fullWidth
        disabled={!email || !password}
        size="large"
        type="submit"
        variant="contained"
        onClick={onSubmit}>
        Login
      </LoadingButton>
    </>
  );
}
