import { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
// @mui
import { Button, IconButton, TextField, Container } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import { showSnackbar } from '../../components/Notification/Snackbar';
import RichTextEditor from '../../components/rich-text-editor';
// Helpers

import SVGIcons from '../../assets/images/icons/svgIcons';
// import TextInput from '../../components/inputs/textInput/textInput';

import FileUploader from '../../components/awsS3ImageUploader/file.uploader';

import './index.scss';
import ApiCaller from '../../services/api/general';
import RichTextViewer from '../../components/rich-text-editor/TextViewer';

// ........................................................................




const AddEditBlog = () => {
    const { id = '', page = 'add' } = useParams();
    const navigate = useNavigate();
    const apiCaller = new ApiCaller('blog');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isTouched, setIsTouched] = useState(false);
    const [isEditEnabled, setIsEditEnabled] = useState(id === undefined || ['add', 'edit'].includes(page));
    const [formData, setFormData] = useState({
        image: {
            fileKey: '',
            tempURL: ''
        },
        title: '',
        subTitle: '',
        shortDescription: '',
        author: '',
        status: 'DRAFT',
        description: ''
    });

    /**
   * sets the form data 
   * @param {string} name the name of the field
   * @param {string} value  the value of the field
   */
    const setFormValue = (name, value) => {
        const formDataAs = { ...formData };
        setFormData({ ...formDataAs, ...{ [name]: value } });
        return true;
    }


    /**
 the legendary use effect function
 This will run very first on render
  */
    useEffect(() => {
        if (id) {
            getData()
        }
        return () => {
            setFormData({})
        };
        // eslint-disable-next-line
    }, []);

    // get users from api
    const getData = () => {
        setIsLoading(true);
        apiCaller.getData(id)
            .then(({ data }) => {
                if (data) {
                    setFormData(data)
                }
            })
            .catch(() => showSnackbar().failure('Something went wrong'))
            .finally(() => setIsLoading(false));
    }


    /**
     * Validates the form and returns true if valid
     * @returns boolean
     */
    const isValidForm = () => {
        let isValid = false;
        if (formData.image?.fileKey && formData.title && formData.subTitle && formData.description) {
            isValid = true;
        }
        return isValid;
    }

    /**
     * On submit the form
     */
    const onSubmit = (status, refreshData = false) => {
        setIsTouched(true);
        if (isValidForm()) {
            const postData = {
                title: formData.title,
                subTitle: formData.subTitle,
                author: formData.author,
                status,
                description: formData.description,
                shortDescription: formData.shortDescription,
                image: formData.image.fileKey
            }
            // Submitting data to backend for registration
            setIsSubmitting(true);
            let message = "Blog created successfully";
            let action = saveBlog;
            if (id) {
                action = updateBlog;
                message = 'Blog updated successfully!';
            }
            action(postData).then((data) => {
                if (data.success) {
                    showSnackbar().success(message)
                } else {
                    showSnackbar().failure(data.message)
                }
            }).finally(() => {
                setIsSubmitting(false);
                if (id && refreshData) {
                    getData()
                }
            });
        }
    };

    /**
     * Saves a news
     */
    const saveBlog = (postData) => apiCaller.postData(postData);

    /**
     * Updates a news using its id
     */
    const updateBlog = (postData) => apiCaller.customPatchData(id, postData);


    console.log(formData, 'formData');

    return (
        <>
            {isLoading && <span className='page-loader'>{SVGIcons.LoaderIcon()}</span>}
            {!isLoading &&

                <Container>
                    <div className='create-news-blk f-wrp'>
                        <div className='btn-wrp navigation-btn-wrp'>
                            <div className='left-btn-box'>
                                <Link to={'/dashboard/blog'} className={`pln-btn back-btn`} ><SVGIcons.BackArrowIcon />Back</Link>
                            </div>
                            {id ? <div className='right-btn-box'>
                                <Button onClick={() => setIsEditEnabled((prv) => !prv)} className={`sqr-btn`} >{isEditEnabled ? 'Cancel' : 'Edit'}</Button>
                                <Button onClick={() => onSubmit(formData.status === 'DRAFT' ? 'PUBLISHED' : 'DRAFT', true)} className={`sqr-btn ${formData?.status}-btn`} >{formData?.status === 'DRAFT' ? 'Publish' : 'Mark as Draft'}</Button>
                            </div> : null}
                        </div>
                        <div className='main-content-box f-wrp'>
                            <div className='sub-header-box f-wrp'>
                                <h3 className='sub-head'><b>{(`${page} Blog`)}</b></h3>
                            </div>
                            <div className='row'>
                                <div className='col-lg-6 col-md-6 col-sm-12 col-xs-12 no-padding-left padding-right-75'>
                                    <div className='each-grid-box standard-form form-full-width panel-form f-wrp'>
                                        <div className='row'>
                                            <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12 no-padding-left no-padding-right'>
                                                <div className='each-TextField'>
                                                    <h4>{("Title")}</h4>
                                                    <TextField
                                                        key={`blog_create_form_Key_title`}
                                                        fullWidth
                                                        name='title'
                                                        disabled={!isEditEnabled}
                                                        id='blog_create_form_question_title'
                                                        value={formData.title}
                                                        type='text'
                                                        isRequired
                                                        placeholder='Title'
                                                        className='custom-textfield'
                                                        helperText={!id && isTouched && !formData.title ? 'Title is required' : ''}
                                                        error={isTouched && !formData.title}
                                                        onChange={(e) => !id ? setFormValue('title', e.target.value) : null}
                                                        useDefaultValidation
                                                    />
                                                </div>
                                            </div>
                                            <div className='col-lg-8 col-md-6 col-sm-12 col-xs-12 no-padding-left padding-right-75'>
                                                <div className='each-TextField'>
                                                    <h4>{("Subtitle")}</h4>
                                                    <TextField
                                                        key={`blog_create_form_Key_subtitle`}
                                                        fullWidth
                                                        name='subTitle'
                                                        disabled={!isEditEnabled}
                                                        id='blog_create_form_question_subtitle'
                                                        value={formData.subTitle}
                                                        type='text'
                                                        isRequired
                                                        placeholder='Subtitle'
                                                        className='custom-textfield'
                                                        helperText={!id && isTouched && !formData.subTitle ? 'Subtitle is required' : ''}
                                                        error={isTouched && !formData.subTitle}
                                                        onChange={(e) => !id ? setFormValue('subTitle', e.target.value) : null}
                                                        useDefaultValidation
                                                    />
                                                </div>
                                            </div>
                                            <div className='col-lg-4 col-md-6 col-sm-12 col-xs-12 no-padding-right padding-left-75'>
                                                <div className='each-TextField'>
                                                    <h4>{("Author")}</h4>
                                                    <TextField
                                                        key={`blog_create_form_Key_author`}
                                                        fullWidth
                                                        name='author'
                                                        disabled={!isEditEnabled}
                                                        id='blog_create_form_question_author'
                                                        value={formData.author}
                                                        type='text'
                                                        required
                                                        placeholder='Author'
                                                        className='custom-textfield'
                                                        helperText={!id && isTouched && !formData.author ? 'Author is required' : ''}
                                                        error={isTouched && !formData.author}
                                                        onChange={(e) => !id ? setFormValue('author', e.target.value) : null}
                                                        useDefaultValidation
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-6 col-md-6 col-sm-12 col-xs-12 no-padding-right padding-left-75'>
                                    <div className='each-grid-box f-wrp'>
                                        <div className='each-TextField'>
                                            <h4>{("Add Image")}</h4>
                                            <div className={`file-upload ${formData?.image?.tempURL ? 'img-added' : 'no-img'}`}>
                                                {isEditEnabled ? <FileUploader
                                                    acceptOnly='image/*'
                                                    uploadCaption={("Select image")}
                                                    onUploadEnd={(file) => setFormValue('image', file)}
                                                /> : null}
                                                {formData?.image?.tempURL ?
                                                    <span className='uploaded-img'>
                                                        {isEditEnabled ? <IconButton children={<SVGIcons.CloseCrossIcon />} onClick={() => setFormValue('image', { fileKey: '', tempURL: '' })} /> : null}
                                                        <img style={{ width: '200px', height: '200px' }} src={formData?.image?.tempURL} alt={"blog"} />
                                                    </span> : null}

                                                {isTouched && !formData.image?.fileKey ? <span style={{ color: 'red' }}>Image is required</span> : null}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12 no-padding-left no-padding-right'>
                                    <div className='each-TextField'>
                                        <h4>{("Short description")}</h4>
                                        <TextField
                                            key={`blog_create_form_Key_shortDescription`}
                                            fullWidth
                                            name='shortDescription'
                                            disabled={!isEditEnabled}
                                            id='blog_create_form_question_shortDescription'
                                            value={formData.shortDescription}
                                            type='text'
                                            required
                                            placeholder='Short description'
                                            className='custom-textfield'
                                            helperText={!id && isTouched && !formData.shortDescription ? 'Author is required' : ''}
                                            error={isTouched && !formData.shortDescription}
                                            onChange={(e) => !id ? setFormValue('shortDescription', e.target.value) : null}
                                            useDefaultValidation
                                        />
                                    </div>
                                </div>
                                <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12 no-padding-left no-padding-right'>
                                    <div className='each-grid-box each-TextField f-wrp'>
                                        <div className='rich-Text-box'>
                                            <h4>{("Description")}</h4>
                                            {isEditEnabled ? <RichTextEditor defaultValue={formData.description} onChange={(value) => setFormValue('description', JSON.stringify(value))} /> : <>
                                                <RichTextViewer richText={formData.description} />
                                            </>}
                                            {isTouched && !formData.description ? <span style={{ color: 'red' }}>Description is required</span> : null}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='btn-wrp navigation-btn-wrp'>
                            <div className='left-btn-box'>
                                <Button onClick={() => navigate('/dashboard/blog')} className='pln-btn'>{("Cancel")}</Button>
                            </div>
                            <div className='right-btn-box'>
                                {isEditEnabled ? <><LoadingButton
                                    loading={isSubmitting}
                                    loadingPosition='start'
                                    startIcon={<></>} // To remove Mui warning
                                    fullWidth
                                    disabled={!isValidForm()}
                                    size="large"
                                    type="submit"
                                    variant="contained"
                                    className='sqr-btn brdr-btn'
                                    onClick={() => onSubmit('DRAFT')}>
                                    {("Save as draft")}
                                </LoadingButton>
                                    <LoadingButton
                                        loading={isSubmitting}
                                        loadingPosition='start'
                                        startIcon={<></>} // To remove Mui warning
                                        fullWidth
                                        disabled={!isValidForm()}
                                        size="large"
                                        type="submit"
                                        variant="contained"
                                        className='sqr-btn'
                                        onClick={() => onSubmit('PUBLISHED')}>
                                        {("Publish")}
                                    </LoadingButton>
                                </>
                                    : null}
                            </div>
                        </div>
                    </div>
                </Container>
            }
        </>
    );
}

AddEditBlog.propTypes = {}

export default AddEditBlog;











