import config from "../config/config";

const {tokenKey} = config;

/**
 * returns true if logged in
 * @returns Bool {is logged in}
 */
export const isLoggedIn = ()=> window.localStorage.getItem(tokenKey)!== null;

/**
 * returns the token if logged in
 * @returns {string} the token
 */
export const getAuthToken = ()=> window.localStorage.getItem(tokenKey);


/**
 * Get the logged in user role name
 * @param {string} key the key of the value needed. Eg: to get email pass email
 * @returns {any} || boolean Incase key is not found, will return empty string
 */
export const getAuthDetailsByKey = (key) => {
    let result = '';
    try {
        const authToken = getAuthToken();
        let extractedToken = {};        
        if (authToken) {
            extractedToken = JSON.parse(window.atob(authToken.split(".")[1]));
        }
        console.log(extractedToken)
        if (key && extractedToken?.details[key]) {
            result = extractedToken?.details[key];
        }
        return result;
    } catch (e) {
        return result;
    }
};



/**
 * logs the user out
 * @returns void
 */
export const logoutUser = ()=> {
    window.localStorage.removeItem(tokenKey);
    window.location.href = "/"
};